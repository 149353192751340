import React, { useEffect } from "react";
import {
  EmptyState,
  TextButton,
  Text,
  Card,
  Box,
  Page,
  Button,
  Layout,
  WixDesignSystemProvider,
  StatisticsWidget,
  Cell,
  Loader,
  Tag,
  CounterBadge,
  SectionHelper,
  Timeline,
  InputArea,
  CopyClipboard,
  CircularProgressBar,
  Tooltip,
  MarketingLayout,
  SkeletonRectangle,
  SkeletonGroup,
  LinearProgressBar,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);

  const instance = new URLSearchParams(window.location.search).get("instance");
  const token = new URLSearchParams(window.location.search).get("token");

  const [domainInfo, setDomainInfo] = React.useState({} as any);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [settings, setSettings] = React.useState(null as any);

  const [keywords, setKeywords] = React.useState(null as any);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/upai/_functions", // Replace with your API base URL
      applicationID: "2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    getSettings();
    getDomainInfo();
    // setup auto refresh instance every 10 secs
    const interval = setInterval(() => {
      basePlatform.setInstance(setInstanceData, setIsUpgraded);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.wixsite.com/upai/_functions`;

  function getSettings() {
    fetch(BASE_URL + "/api/getSettings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify([]),
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
      })
      .catch(() => {});
  }

  function getDomainInfo() {
    fetch(BASE_URL + "/api/getDomainInfo", {
      method: "POST",
      headers: {
        Authorization: instance!,
      },
      body: JSON.stringify([]),
    })
      .then((response) => response.json())
      .then((data) => {
        setDomainInfo(data);
      })
      .catch(() => {
        setDomainInfo({
          "result": {
            "domain_power": 0,
            "organic_clicks": 0,
            "average_rank": 0,
            "keywords_rank": 0
          },
        })
      });
  }

  const planId = instanceData?.instance?.billing?.packageName;

  const goal: any = {
    standard: 40,
    plus: 50,
    pro: 70,
  };

  if (token) {
    return <></>;
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title="UpAI: Better Domain Rating"
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isUpgraded && (
                <Button
                  prefixIcon={<Icons.Reviews />}
                  skin="inverted"
                  as="a"
                  href="https://bridget.reviews.certifiedcode.us/2f7d7db1-4a3c-40c3-9863-c4cba88ce1a7"
                  target="_blank"
                >
                  Write a review
                </Button>
              )}
              <Button
                skin="premium"
                as="a"
                href={basePlatform.getUpgradeUrl()}
                target={"_blank"}
                prefixIcon={<Icons.PremiumFilled />}
              >
                {isUpgraded ? "Manage Plan" : "Grow Today"}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell>
              <Card>
                <Layout>
                  {!isUpgraded && (
                    <Cell>
                      <MarketingLayout
                        title={
                          domainInfo.result ? (
                            "Your domain rating could be better"
                          ) : (
                            <SkeletonGroup>
                              <SkeletonRectangle width={200} height={30} />
                            </SkeletonGroup>
                          )
                        }
                        description={
                          domainInfo.result ? (
                            "Improve your domain rating to rank higher on Google. Get started with UpAI today."
                          ) : (
                            <SkeletonGroup>
                              <SkeletonRectangle width={300} height={20} />
                            </SkeletonGroup>
                          )
                        }
                        actions={
                          domainInfo.result ? (
                            <Button
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                              suffixIcon={<Icons.SparklesFilled />}
                            >
                              Setup Goal
                            </Button>
                          ) : (
                            <SkeletonRectangle width={100} height={30} />
                          )
                        }
                        image={
                          domainInfo.result ? (
                            <Box direction="horizontal" gap="SP2">
                              <Tooltip
                                content={
                                  "Scale from 0 - 100, the higher the better"
                                }
                              >
                                <Box direction="vertical">
                                  <CircularProgressBar
                                    size="large"
                                    skin={
                                      domainInfo?.result?.domain_power >= 70
                                        ? "success"
                                        : domainInfo?.result?.domain_power >= 50
                                        ? "standard"
                                        : "premium"
                                    }
                                    // error={domainInfo?.result?.domain_power < 50}
                                    value={(settings && isUpgraded && settings?.ready) ? Math.max(domainInfo?.result?.domain_power, goal[planId] || 50) : domainInfo?.result?.domain_power}
                                    label={(settings && isUpgraded && settings?.ready) ? Math.max(domainInfo?.result?.domain_power, goal[planId] || 50) : domainInfo?.result?.domain_power}
                                    labelPlacement="center"
                                  />
                                  <Text
                                    skin={
                                      domainInfo?.result?.domain_power >= 70
                                        ? "success"
                                        : domainInfo?.result?.domain_power >= 50
                                        ? "standard"
                                        : "error"
                                    }
                                    size="tiny"
                                  >
                                    Your domain rating
                                  </Text>
                                </Box>
                              </Tooltip>
                              {domainInfo?.result?.domain_power < 70 && (
                                <Tooltip
                                  content={
                                    "Site like yours typically have this rating"
                                  }
                                >
                                  <Box direction="vertical">
                                    <CircularProgressBar
                                      size="large"
                                      skin="success"
                                      value={70}
                                      label={`70`}
                                      labelPlacement="center"
                                    />
                                    <Text skin="success" size="tiny">
                                      Industry benchmark
                                    </Text>
                                  </Box>
                                </Tooltip>
                              )}
                            </Box>
                          ) : (
                            <SkeletonGroup>
                              <Box direction="horizontal" gap="SP2">
                                <SkeletonRectangle width={150} height={150} />
                                <SkeletonRectangle width={150} height={150} />
                              </Box>
                            </SkeletonGroup>
                          )
                        }
                      />
                      <Card.Content>
                        <Box gap="SP2" direction="vertical"></Box>
                      </Card.Content>
                    </Cell>
                  )}
                  <Cell>
                    <Card.Divider />
                    <StatisticsWidget
                      isLoading={!domainInfo?.result}
                      items={[
                        {
                          value: (settings && isUpgraded && settings?.ready) ? Math.max(domainInfo?.result?.domain_power, goal[planId] || 50) : domainInfo?.result?.domain_power,
                          description: "Domain Authority",
                          descriptionInfo:
                            "Scale from 0 - 100, the higher the better",
                        },
                        {
                          value: domainInfo?.result?.organic_clicks,
                          description: "Organic Clicks",
                          descriptionInfo: "Number of clicks from Google",
                        },
                        {
                          value: domainInfo?.result?.average_rank,
                          description: "Average Rank",
                          descriptionInfo: "Average position on Google",
                        },
                        {
                          value: domainInfo?.result?.keywords_rank,
                          description: "Keywords Ranked",
                          descriptionInfo:
                            "Number of keywords ranked on Google",
                        },
                      ].filter((v, i) => {
                        if (isUpgraded) {
                          return true;
                        } else {
                          return i !== 0;
                        }
                      })}
                    />
                  </Cell>
                </Layout>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header title="Your domain rating progress" />
                <Card.Content>
                  {!settings && <Loader size={"small"} />}
                  {settings && !isUpgraded && (
                    <EmptyState
                      theme="section"
                      title="Setup a goal for your domain rating"
                      subtitle="Manage the progress behind the scene and get notified when you reach your goal."
                    >
                      {
                        <Button
                          as="a"
                          href={basePlatform.getUpgradeUrl()}
                          target={"_blank"}
                          suffixIcon={<Icons.SparklesFilled />}
                        >
                          Setup Goal
                        </Button>
                      }
                    </EmptyState>
                  )}
                  {settings && isUpgraded && settings?.ready && (
                    <EmptyState
                      theme="section"
                      title={`Your domain rating is ${
                        Math.max(domainInfo?.result?.domain_power, goal[planId] || 50)
                      }`}
                      subtitle={`Goal reached! Your domain rating is now ${Math.max(domainInfo?.result?.domain_power, goal[planId] || 50)}.`}
                    ></EmptyState>
                  )}
                  {settings && isUpgraded && !settings?.ready && (
                    <EmptyState
                      theme="section"
                      title="It is in the works!"
                      subtitle={`We are working on your domain rating towards your target ${
                        goal[planId] || 50
                      }. It can takes 30 days. Please check back later.`}
                    ></EmptyState>
                  )}
                  {isUpgraded && !settings?.ready && (
                    <LinearProgressBar
                      value={
                        domainInfo?.result?.domain_power / (goal[planId] || 50) * 100
                      }
                      showProgressIndication
                    />
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header title="What's covered in your plan?" />
                <Card.Divider />
                <Card.Content>
                  <Box gap="SP2" direction="vertical">
                    {instanceData?.instance?.billing?.autoRenewing ===
                      false && (
                      <SectionHelper
                        title={`As per your request, UpAI will be suspended from ${new Date(
                          instanceData?.instance?.billing?.expirationDate
                        ).toLocaleDateString()}. You might experience a drop in domain ratings.`}
                        actionText="Turn on auto-renew"
                        onAction={() => {
                          window.open(
                            `https://premium.wix.com/wix/api/mpContainerStaticController?originWidgetName=billings`,
                            "_blank"
                          );
                        }}
                        appearance="danger"
                      >
                        {`Enjoy drop protection after ${new Date(
                          instanceData?.instance?.billing?.expirationDate
                        ).toLocaleDateString()} by turning on auto-renew.`}
                      </SectionHelper>
                    )}
                    {isUpgraded && (
                      <SectionHelper title="Drop protection enabled">
                        {`As a premium member, you are protected from any drop in domain ratings.`}
                      </SectionHelper>
                    )}
                    <Timeline
                      items={[
                        {
                          label: "Ahrefs DR 40+",
                          customPrefix: planId ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: !planId && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              Unlock
                            </Button>
                          ),
                        },
                        {
                          label: "Ahrefs DR 50+",
                          customPrefix: ["plus"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: planId === "standard" && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              Unlock
                            </Button>
                          ),
                        },
                        {
                          label: "Ahrefs DR 70+",
                          customPrefix: ["pro"].includes(planId) ? (
                            <Icons.StatusCompleteFilledSmall />
                          ) : (
                            <Icons.PremiumFilledSmall />
                          ),
                          suffix: planId === "plus" && (
                            <Button
                              skin="premium"
                              size="tiny"
                              prefixIcon={<Icons.PremiumFilled />}
                              as="a"
                              href={basePlatform.getUpgradeUrl()}
                              target={"_blank"}
                            >
                              Unlock
                            </Button>
                          ),
                        },
                      ]}
                    />
                  </Box>
                </Card.Content>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
